import React, { memo } from "react";
import classNames from "classnames";
import { VariantDto } from "@ecp-redux/dto/searchResults.types";
import LinkWrapper from "../../../../../../global/components/LinkWrapper/LinkWrapper";
import StyledOption from "../../../../../../shared/styleElements/StyledOption/StyledOption";
import StyledButton from "../../../../../styleElements/StyledButton/StyledButton";
import { TVariant } from "../../ProductVariants/ProductVariants.types";
import { StyledVariantsContainer } from "./VariantsList.styled";
import { useVariantsList } from "./useVariantsList";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";

interface IVariantsListProps {
  variantSettings: TVariant;
  variants: VariantDto[];
  productSku: string;
  productUrl: TUrl;
}

const VariantsList: React.FC<IVariantsListProps> = ({
  variantSettings,
  variants,
  productSku,
  productUrl,
}) => {
  const {
    wrapperRef,
    elementRefs,
    wrapperWidth,
    elementsInRow,
    showLabel,
    labelText,
    optionStyle,
    labelStyle,
    maxNumbersOfRows,
  } = useVariantsList(variantSettings);

  const setRef = (index: number) => (el: HTMLButtonElement | null) => {
    if (el) {
      elementRefs.current[index] = el;
    }
  };

  return (
    <StyledVariantsContainer
      ref={wrapperRef}
      key={wrapperWidth}
      $variantSettings={variantSettings}
      $showDefaultLabelButton={!!elementsInRow.length && showLabel}
      className="variants-list"
    >
      {!elementsInRow.length &&
        variants
          .filter((v) => v.inStock)
          .map((variant, index) => (
            <StyledOption
              key={variant.sku + index}
              className={classNames("variants-list__element")}
              active={variant.sku === productSku}
              option={optionStyle}
              url={variant.productUrl}
              ref={setRef(index)}
              text={variant.attribute.label}
            />
          ))}

      {(elementsInRow.length && !(elementsInRow.length > maxNumbersOfRows)) ||
      !showLabel ? (
        elementsInRow.map(
          (row, index) =>
            index < maxNumbersOfRows && (
              <div className="variants-list__sorted-list" key={index}>
                {row.map((element, idx) => {
                  const { url } = element.dataset;
                  const isActiveVariant =
                    element.classList.contains("active-button");

                  return (
                    <LinkWrapper key={idx} href={url}>
                      <StyledOption
                        className={classNames("variants-list__sorted-element")}
                        option={optionStyle}
                        disabled={!url}
                        active={isActiveVariant}
                        text={element.textContent ?? ""}
                      />
                    </LinkWrapper>
                  );
                })}
              </div>
            )
        )
      ) : (
        <LinkWrapper href={productUrl}>
          <StyledButton
            renderAs="button"
            className="variants-list__label_element"
            $settings={labelStyle}
          >
            <i />
            {labelText}
          </StyledButton>
        </LinkWrapper>
      )}
    </StyledVariantsContainer>
  );
};

export default memo(VariantsList);
