import { useTheme } from "styled-components";
import {
  IThemeState,
  TOptionId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { forwardRef } from "react";
import StyledButton from "../StyledButton/StyledButtonWrapper.styled";
import classNames from "classnames";

interface OptionProps {
  option: TOptionId;
  text: string;
  className?: string;
  disabled?: boolean;
  url?: string;
  active?: boolean;
}

const StyledOption = forwardRef<HTMLButtonElement, OptionProps>(
  (
    { disabled = false, url, option, text, className, active = false },
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      globalObjects: { options },
    } = useTheme() as IThemeState;

    const optionSettings = options.find((o) => o.id === option)?.button;

    if (!optionSettings) return null;

    return (
      <StyledButton
        renderAs="button"
        className={classNames(className, active && "active-button")}
        $settings={optionSettings}
        data-url={url}
        disabled={disabled}
        ref={ref}
      >
        {text}
      </StyledButton>
    );
  }
);

export default StyledOption;
